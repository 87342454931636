<template>
  <div
    class="record_info"
    v-loading="loading"
    element-loading-text="准备中"
    element-loading-spinner="el-icon-loading"
  >
    <div class="top_card">
      <div class="head_img">
        <div class="img_title">
          <div class="img">
            <img src="../../../../static/images/Snipaste.png" alt="" />
          </div>
          <div class="title">
            <p class="merchant_name">{{ topinfo.companyName }}
              <span style="color:#999999;font-size:14px" v-if="h5info&&h5info.researchMethod">
                (<span v-for="(o,i) in h5info.researchMethod" :key="i">
              <span v-if="o==1">电话沟通-</span>
              <span v-if="o==2">线上会议</span>
              <span v-if="o==3">-企业拜访</span>
              <span v-if="o==4">活动见面</span>
              <span v-if="o==5">-参观</span>
              <span v-if="o==6">-座谈</span>
              <span v-if="o==7">-其他</span>
              </span>)
              </span>
            </p>
            <span v-if="toplist.length > 0" class="merchant_type">{{ toplist[0] }}</span>
          </div>
        </div>
        <!-- @mouseenter="issShowDonwloadBtn = true"
            @mouseleave="issShowDonwloadBtn = false" -->
        <!-- <div class="show_btns" v-show="issShowDonwloadBtn">
              <div class="span_btn" >简易版图片</div>
              <div class="span_btn" >复杂版图片</div>
            </div> -->
        <div class="right_buttom" v-if="diagnosisresult.evaluationType !== '3'">
          <!-- <el-button type="warning" @click="upReport">发布报告</el-button> -->
          <div class="btn_item">
            <el-button type="primary" @click="downloadSimpleImage">下载简易版</el-button>
          </div>
          <!--
            此功能后续再开发
            <el-button type="primary" plain @click="historyClicck">历史诊断记录</el-button> -->
          <div class="btn_item">
            <el-button type="info" plain @click="downloadPdf" :disabled="btns.downloadPdfBtn"
              >下载分析报告</el-button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="card_content">
      <!-- 左边导航 -->
      <div class="card_left">
        <div class="navigation_top">快速导航</div>
        <div class="navigation_content">
          <el-collapse v-model="activeNames" @change="collapseChanged">
            <div class="first_round"></div>
            <div class="second_round"></div>
            <!-- 一级问题组 collapse-item -->
            <el-collapse-item
              v-for="(item, index) in recordlist"
              :key="index"
              :name="index"
              @click.native="collapseFirstItemClick(item, index)"
              :class="getFirstItemClass(item, index, 1)"
            >
              <template slot="title">
                <div class="nav_level_one">
                  <div v-if="getActiveItem(index)" class="triangle_box"></div>
                  <div v-else class="li_round"></div>
                  <!-- 一级问题组名称 -->
                  <span>{{ item.groupName }} </span>
                </div>
              </template>
              <div v-if="!item.vos">
                <!-- 二级问题组 collapse-item -->
                <el-collapse-item
                  v-for="(i1, i2) in item.results"
                  :key="i2"
                  :name="getSecondItemName(index, i2)"
                  @click.native.stop="collapseSecondItemClick(i1, i2, index)"
                  :class="getSecondItemClass(i1, i2, index)"
                >
                  <template slot="title">
                    <div class="nav_level_two">
                      <!-- 二级问题组名称 -->
                      <span>{{ i1.groupName }}</span>
                    </div>
                  </template>
                  <div v-if="!i1.vos">
                    <!-- 三级问题组 collapse-item -->
                    <el-collapse-item
                      v-for="(ii1, ii2) in i1.results"
                      :key="ii2"
                      :name="getThirdItemName(index, ii2)"
                      @click.native.stop="collapseThirdItemClick(ii2, index)"
                    >
                      <template slot="title">
                        <div class="nav_level_three">
                          <!-- 三级问题组名称 -->
                          <span>{{ ii1.groupName }}</span>
                        </div>
                      </template>
                    </el-collapse-item>
                  </div>
                </el-collapse-item>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <!-- 左边卡片 -->
      <el-card class="body_card">
        <!-- accordion -->
        <el-collapse v-model="activeNames">
          <!-- 一级问题组 collapse-item -->
          <el-collapse-item
            v-for="(item, index) in recordlist"
            :key="index"
            :name="index"
            @click.native="collapseFirstItemClick(item, index)"
          >
            <template slot="title">
              <div class="collapse_title">
                <!-- 一级问题组名称 -->
                <span class="group_name_one">{{ item.groupName }} </span>
                <span v-if="hasScore" style="margin-left: 20px" class="corlor2"
                  >{{ item.atPresentScore }}分</span
                >
              </div>
            </template>
            <div v-if="!item.vos" style="padding-left: 20px">
              <!-- 展示二级问题组 -->
              <el-collapse-item
                v-for="(i1, i2) in item.results"
                :key="i2"
                :name="getSecondItemName(index, i2)"
                @click.native.stop="collapseSecondItemClick(i1, i2, index)"
              >
                <template slot="title">
                  <div class="collapse_title">
                    <!-- 二级问题组名称 -->
                    <span class="group_name_two">{{ i1.groupName }}</span>
                    <span v-if="hasScore" style="margin-left: 20px" class="corlor2"
                      >{{ i1.atPresentScore }}分</span
                    >
                  </div>
                </template>
                <div v-if="!i1.vos" style="padding-left: 20px">
                  <!-- 展示三级问题组 -->
                  <el-collapse-item
                    v-for="(ii1, ii2) in i1.results"
                    :key="ii2"
                    :name="getThirdItemName(index, ii2)"
                    @click.native.stop="collapseThirdItemClick(ii2, index)"
                  >
                    <template slot="title">
                      <div class="collapse_title">
                        <!-- 三级问题组名称 -->
                        <span class="group_name_three">{{ ii1.groupName }}</span>
                        <span v-if="hasScore" style="margin-left: 20px" class="corlor2"
                          >{{ ii1.atPresentScore }}分</span
                        >
                      </div>
                    </template>
                    <el-timeline>
                      <el-timeline-item v-for="(iii1, iii2) in ii1.vos" :key="iii2">
                        <template>
                          <div>
                            <!-- 第一行--问题名称 -->
                            <div class="row">
                              <div class="item1">
                                <span>{{ iii2 + 1 + '.' }}</span>
                                <span style="margin-right: 40px">
                                  {{ iii2 + 1 }}.{{ iii1.caption }}
                                </span>
                              </div>
                              <span> 分数：{{ iii1.points == null ? 0 : iii1.points }} </span>
                            </div>
                            <!-- 第二行--选择的选项集合 -->
                            <div style="overflow: auto" v-if="!iii1.optionList">
                              {{ iii1.optionData }}
                            </div>
                            <div style="overflow: auto" v-else>
                              <div
                                style="overflow: auto; margin-bottom: 10px"
                                v-for="(iiii1, iiii2) in iii1.optionList"
                                :key="iiii2"
                              >
                                {{ iiii1 }}
                              </div>
                            </div>

                            <!-- 第三行--选项说明 -->
                            <div class="top_start" v-if="iii1.descriptionOfOptions != null">
                              <span class="txt"> ({{ iii1.descriptionOfOptions }}) </span>
                            </div>
                            <!-- 第四行--未选择的选项集合 -->
                            <div
                              class="top_start"
                              v-if="iii1.notSelectOptions && iii1.notSelectOptions.length > 0"
                            >
                              <div class="not_select_options">未选择选项</div>
                              <div
                                class="not_option_txt"
                                v-for="(iiii1, iiii2) in iii1.notSelectOptions"
                                :key="iiii2"
                              >
                                <div class="order_box">{{ iiii2 + 1 }}</div>
                                {{ iiii1 }}
                              </div>
                            </div>
                          </div>
                        </template>
                      </el-timeline-item>
                    </el-timeline>
                  </el-collapse-item>
                </div>
                <el-timeline v-else>
                  <!-- 展示问题 -->
                  <el-timeline-item v-for="(ii1, ii2) in i1.vos" :key="ii2">
                    <template>
                      <div>
                        <!-- 第一行--问题名称 -->
                        <div class="row">
                          <div class="item1">
                            <span>{{ ii2 + 1 + '.' }}</span>
                            <span style="margin-right: 40px">
                              {{ ii1.caption }}
                            </span>
                          </div>
                          <span> 分数：{{ ii1.points == null ? 0 : ii1.points }} </span>
                        </div>
                        <!-- 第二行--选择的选项集合 -->
                        <div style="overflow: auto" v-if="!ii1.optionList">
                          {{ ii1.optionData }}
                        </div>
                        <div style="overflow: auto" v-else>
                          <div
                            style="overflow: auto; margin-bottom: 10px"
                            v-for="(iii1, iii2) in ii1.optionList"
                            :key="iii2"
                          >
                            {{ iii1 }}
                          </div>
                        </div>

                        <!-- 第三行--选项说明 -->
                        <div class="top_start" v-if="ii1.descriptionOfOptions != null">
                          <span class="txt"> ({{ ii1.descriptionOfOptions }}) </span>
                        </div>
                        <!-- 第四行--未选择的选项集合 -->
                        <div
                          class="top_start"
                          v-if="ii1.notSelectOptions && ii1.notSelectOptions.length > 0"
                        >
                          <div class="not_select_options">未选择选项</div>
                          <div
                            class="not_option_txt"
                            v-for="(iii1, iii2) in ii1.notSelectOptions"
                            :key="iii2"
                          >
                            <div class="order_box">{{ iii2 + 1 }}</div>
                            {{ iii1 }}
                          </div>
                        </div>
                      </div>
                    </template>
                  </el-timeline-item>
                </el-timeline>
              </el-collapse-item>
            </div>
            <el-timeline v-else>
              <!-- 展示问题 -->
              <el-timeline-item v-for="(i1, i2) in item.vos" :key="i2">
                <template>
                  <div>
                    <!-- 第一行--问题名称 -->
                    <div class="row">
                      <div class="item1">
                        <span>{{ i2 + 1 + '.' }}</span>
                        <span style="margin-right: 40px">
                          {{ i1.caption }}
                        </span>
                      </div>
                      <span> 分数：{{ !i1.points ? 0 : i1.points }} </span>
                    </div>
                    <!-- 第二行--选择的选项集合 -->
                    <div style="overflow: auto" v-if="!i1.optionList">
                      {{ i1.optionData }}
                    </div>
                    <div style="overflow: auto" v-else>
                      <div
                        style="overflow: auto; margin-bottom: 10px"
                        v-for="(ii1, ii2) in i1.optionList"
                        :key="ii2"
                      >
                        {{ ii1 }}
                      </div>
                    </div>
                    <!-- 第三行--选项说明 -->
                    <div class="top_start" v-if="i1.descriptionOfOptions">
                      <span class="txt"> ({{ i1.descriptionOfOptions }}) </span>
                    </div>
                    <!-- 第四行--未选择的选项集合 -->
                    <div
                      class="top_start"
                      v-if="i1.notSelectOptions && i1.notSelectOptions.length > 0"
                    >
                      <div class="not_select_options">未选择选项</div>
                      <div
                        class="not_option_txt"
                        v-for="(ii1, ii2) in i1.notSelectOptions"
                        :key="ii2"
                      >
                        <div class="order_box">{{ ii2 + 1 }}</div>
                        {{ ii1 }}
                      </div>
                    </div>
                  </div>
                </template>
              </el-timeline-item>
            </el-timeline>
          </el-collapse-item>
        </el-collapse>
      </el-card>
      <!-- 右边卡片 -->
      <el-card class="body_card" v-if="diagnosisresult.evaluationType !== '3'">
        <div class="head_img">
          <div class="img_title">
            <div class="img">
              <img src="../../../../static/images/Group 3173.png" alt="" />
            </div>
            <div class="title">
              <p class="merchant_name">{{ topinfo.name }}</p>
              <span v-if="citylist.length > 0" class="merchant_city">
                {{ citylist[0] }}
              </span>
              <span v-if="citylist[1] != null"> -{{ citylist[1] }} </span>
              <span v-if="citylist[2] != null"> -{{ citylist[2] }} </span>
              <span
                v-if="citylist.length > 0"
                style="height: 12px; display: inline-block; border-left: 2px solid #999999"
              ></span>
              <span class="merchant_phone">{{ topinfo.phone }}</span>
              <span
                v-if="topinfo.phone != null"
                style="height: 12px; display: inline-block; border-left: 2px solid #999999"
              ></span>

              <span class="merchant_companyName">{{ topinfo.position }}</span>
            </div>
          </div>
          <div v-if="diagnosisresult.evaluationType == '2'" class="right_txt">
            <div style="margin-right: 10px">
              可申报政策（项）
              <span class="policy_num">{{
                policyobj.returnableNumber ? policyobj.returnableNumber : 0
              }}</span>
            </div>
            <div style="width: 0px; height: 24px; border: 1px solid #d4d4d4"></div>
            <div style="margin-left: 10px">
              不可申报政策（项）
              <span class="policy_num">{{
                policyobj.noReturnableNumber ? policyobj.noReturnableNumber : 0
              }}</span>
            </div>
          </div>
          <div class="right_txt" v-else>
            <div style="margin-right: 20px">
              <span>等级</span>
              <span class="large_font">{{ getGrade }}</span>
              <span v-if="getGrade == 'L1'">(规划级)</span>
              <span v-else-if="getGrade == 'L2'">(规范级)</span>
              <span v-else-if="getGrade == 'L3'">(集成级)</span>
              <span v-else-if="getGrade == 'L4'">(优化级)</span>
              <span v-else-if="getGrade == 'L5'">(引领级)</span>
            </div>
            <div style="width: 0px; height: 24px; border: 1px solid #d4d4d4"></div>
            <div style="margin-left: 20px">
              <span>分数</span>
              <span class="large_font">{{ reportScore }}</span>
              <span>分</span>
            </div>
          </div>
        </div>
       <div v-if="h5info">
        <div class="right_reception">
              <div class="reception_tittle">
                <span>接待人信息</span>
              </div>
              <div class="reception_name" >
                <div class="reception_item" v-for="(item,index) in h5info.participantId" :key="index">
                  <img src="../../../../static/images/Group 3173.png" alt="">
                  <div class="bottom">
                    <div class="bottom_name">{{item.name}}</div>
                    <div class="bottom_txt">{{item.mobile}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="right_reception">
              <div class="reception_tittle">
                <span>参与人信息</span>
              </div>
              <div class="reception_name" >
                <div class="reception_item" v-for="(item,index) in h5info.receiverId" :key="index">
                  <img src="../../../../static/images/Group 3173.png" alt="">
                  <div class="bottom">
                    <div class="bottom_name">{{item.name}}
                    <span class="color_txt">({{item.institution}})</span>
                    </div>
                    <div class="bottom_txt">{{item.mobile}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="right_reception">
              <div class="reception_tittle">
                <span>参与专家</span>
              </div>
              <div class="reception_expert" v-for="(item,index) in spiclist" :key="index" >
                <div class="expert_top">
                  <img :src="item.headPortrait" alt="">
                  <span>{{item.name}}</span>
                </div>
                <div class="txt_size">
                  <span>{{item.specialistIntro}}</span>
                </div>
              </div>
            </div>
       </div>
        <div class="tabs">
       
          <div :class="activeName === '1' ? 'activeTab' : 'tab'" @click="tabClick('1')">
            复杂视图
          </div>
          <div :class="activeName === '2' ? 'activeTab' : 'tab'" @click="tabClick('2')">
            简易视图
          </div>
          <!-- updateTime -->
          <div class="txt">提交时间：{{ diagnosisresult.createTime }}</div>
        </div>
        <div class="right_content">
          <div>
           
            <!-- 评估依据 -->
            <div class="right_item">
              <div class="item_tittle box_border">
                <span>评估依据</span>
              </div>
              <div class="row">
                <!-- :disabled="activeName === '2'" -->
                <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="请输入评估依据"
                  v-model="paramsug.diagnosticBasis"
                  readonly
                >
                </el-input>
              </div>
            </div>
            <!-- 申报政策 -->
            <div class="right_item" v-if="diagnosisresult.evaluationType == '2'">
              <div class="item_tittle box_border">
                <span>申报政策</span>
              </div>
              <div class="declarable_policy" v-if="diagnosisresult.evaluationType == '2'">
                <el-collapse>
                  <el-collapse-item
                    v-for="(item, index) in policyobj.declarationPolicyVo"
                    :key="index"
                  >
                    <template slot="title">
                      <div class="policy_optioinNo" v-if="item.isDeclaration == '1'">
                        <i class="el-icon-success"></i>
                        <span style="font-size: 16px">{{ item.declarationPolicyName }}</span>
                      </div>
                      <div class="policy_optioin" v-else-if="item.isDeclaration == '2'">
                        <i class="el-icon-success"></i>
                        <span style="font-size: 16px">{{ item.declarationPolicyName }}</span>
                      </div>
                    </template>
                    <div v-if="item.isDeclaration == '2'">
                      <div>
                        <span>{{ item.supportStandard }}</span>
                      </div>
                    </div>
                    <div v-if="item.isDeclaration == '1'" style="overflow: auto">
                      <div v-if="item.noDeclarationReason != null">
                        <span>{{ item.noDeclarationReason }}</span>
                      </div>
                      <div v-if="item.noDeclarationReason == null">
                        <!-- <div v-for="(el, index) in item.questionsManufacture" :key="index">
                          <div class="caption">{{ el.caption }}</div>
                          <div class="caption_chid">{{ el.noDeclarationReason }}</div>
                        </div> -->
                        <div v-for="(el, index) in item.questionsFactory" :key="index">
                          <div class="caption">{{ el.caption }}</div>
                          <div class="caption_chid">{{ el.noDeclarationReason }}</div>
                        </div>
                        <div v-for="(el, index) in item.questionsWorkshop" :key="index">
                          <div class="caption">{{ el.caption }}</div>
                          <div class="caption_chid">{{ el.noDeclarationReason }}</div>
                        </div>
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
            <!-- 分析图例 -->
            <div class="right_item">
              <div class="legend" v-if="hasScore">
                <div class="box_border item_tittle">
                  <span>分析图例</span>
                </div>
                <div v-if="recordlist.length > 0 && hasScore">
                  <span style="color: #ccc; font-size: 12px">得分 </span>
                  <span style="color: #ff7a00; font-weight: 500">{{ reportScore }}分</span>
                </div>
              </div>
              <div v-if="hasScore">
                <div class="legend_item" v-for="(item, index) in myChartList" :key="index">
                  <div class="item_name">
                    <div class="img_box">
                      <img
                        v-if="item.id == 'myChart1'"
                        src="../../../assets/image/sum.png"
                        alt=""
                      />
                      <img
                        v-else-if="item.id == 'myChart2'"
                        src="../../../assets/image/planning.png"
                        alt=""
                      />
                      <img
                        v-else-if="item.id == 'myChart3'"
                        src="../../../assets/image/base.png"
                        alt=""
                      />
                      <img v-else src="../../../assets/image/apply.png" alt="" />
                    </div>
                    <span>{{ item.data.name }}</span>
                  </div>
                  <!-- 雷达图 -->
                  <div
                    class="specificechart"
                    style="width: 600px; height: 400px; margin: auto"
                  ></div>
                </div>
              </div>
            </div>
            <!-- 底部建议 -->
            <div class="right_item">
              <div class="item_tittle box_border">
                <span>综合评估建议</span>
              </div>
              <div class="row">
                <!-- :disabled="activeName === '2'" -->
                <el-input
                  type="textarea"
                  :rows="4"
                  placeholder="请输入综合评估建议"
                  v-model="paramsug.evaluationSuggestion"
                  readonly
                  maxlength="200"
                  show-word-limit
                >
                </el-input>
              </div>
             
              <div   v-if="h5info">
                <div class="question">
                <div class="question_types">
                  <span>专家建议</span>
                  <span style="font-size: 12px">（专家建议最多5条）</span>
                </div>
                <div class="spise_box" v-for="(item, index) in paramList" :key="index">
                  <div class="spise_top">
                    <div class="spise_flex">
                      <img :src="item.headPortrait" alt="" />
                      <span>{{ item.name }}</span>
                    </div>
                    <div class="blue_color" @click="addsugges(index)">
                      <span>添加建议</span>
                    </div>
                  </div>
                  <div class="suggestion" v-for="(el, i) in item.specialistSuggestion" :key="i">
                    <div class="sugges_left part_txt">
                      <span class="blue_index">{{ i + 1 }}</span>
                      <el-input type="textarea" :rows="4" v-model="el.suggestion" />
                    </div>
                    <div class="delete_sugges" @click="delsugges(item, i)">
                      <span>删除建议</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item_tittle box_border flex_xian">
                <span>现场记录</span>
              </div>
              <div class="xianch_img">
                <el-image
                class="img"
                  v-for="(el, index) in h5info.livePicture" :key="index" :src="el" 
                  :preview-src-list="h5info.livePicture"
                >
                </el-image>
               
              </div>
              </div>
              <div v-else>
                <div class="part">
                <div class="item_tittle box_border">
                  <span>专家建议</span>
                </div>
                
              </div>
              <div v-for="(item, index) in paramsug.suggest" :key="index">
                <div class="part_top">
                  <div style="padding: 10px">建议{{ index + 1 }}</div>
                  <div class="i_box" @click="delsugest(index)" v-if="index > 0">
                    <i class="el-icon-delete"></i>
                  </div>
                </div>
                <div class="part_txt">
                 
                  <el-input placeholder="请输入建议标题" v-model="item.suggestTitle" readonly>
                  </el-input>
                  <el-input
                    type="textarea"
                    placeholder="请输入建议内容"
                    v-model="item.suggest"
                    readonly
                  >
                   
                  </el-input>
                </div>
              </div>
              </div>
            </div>
            <!-- 分享配置 -->
            <!-- <div class="right_item">
              <div class="box_border item_tittle">
                <span>分享配置</span>
              </div>
              <div class="row">
                <span class="label">分享链接标语</span>
                <div class="common_input">
                  <el-input
                    placeholder="请输入一句话介绍"
                    v-model="paramsug.appLinkSlogan"
                    :disabled="activeName === '2'"
                  >
                  </el-input>
                </div>
              </div>
              <div class="row">
                <span class="label">分享链接图片</span>
                <div class="img_box">
                  <img-big-upload
                    :imageShow.sync="paramsug.appLinkPicture"
                    :addTitleShow="false"
                    @removeImg="removeImgAppLinkPicture"
                    @uploadOneImgShow="uploadOneImgShowAppLinkPicture"
                  />
                  <span class="corlor1"
                    >(此图片应用小程序分享链接，建议整体尺寸为630*510px，图片应小于6MB)
                  </span>
                </div>
              </div>
            </div> -->
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="saveSpecialistSuggestiond">保存</el-button>
              <el-button type="primary" plain @click="back">返回上一级</el-button>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <!-- 拒绝的理由弹窗 -->
    <el-dialog
      title="不可申报理由"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="refuse">
        <el-form
          :model="paramdiagnose"
          :rules="rules"
          ref="ruleForm"
          label-width="60px"
          class="demo-ruleForm"
        >
          <el-form-item label="理由" prop="noDeclarationReason">
            <el-input
              type="textarea"
              :rows="10"
              placeholder="请输入不可申报理由"
              v-model="paramdiagnose.noDeclarationReason"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 历史诊断记录弹窗 -->
    <el-dialog title="历史诊断记录" :visible.sync="open" append-to-body>
      <div class="head_img">
        <div class="img_title">
          <div class="img">
            <img src="../../../../static/images/Group 3173.png" alt="" />
          </div>
          <div class="title">
            <p class="merchant_name">{{ topinfo.companyName }}</p>
            <span v-if="toplist[0]" class="merchant_type">{{ toplist[0] }}</span>
          </div>
        </div>
        <div class="right_box">
          <div class="sumit_num">
            <span>{{ historytotal }}</span>
          </div>
          <div>
            <span>总提交次数</span>
          </div>
        </div>
      </div>
      <el-table ref="multipleTable" :data="historyList" row-key="buildingId">
        <el-table-column label="序号" type="index" align="center" prop="diagnoseId" width="50px">
          <template slot-scope="scope">
            <span>{{ (hisparam.pageNum - 1) * hisparam.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="可申报政策" align="center" prop="returnable" />
        <el-table-column label="不可申报政策" align="center" prop="noReturnable" />
        <el-table-column label="提交人" align="center" prop="createName" />
        <el-table-column label="提交时间" align="center" prop="createTime" />
        <el-table-column label="操作" align="center" prop="">
          <template slot-scope="scope">
            <el-button type="text" @click="toHistoryRecord(scope.row)">详情 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="hisparam.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="hisparam.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="historytotal"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-dialog>
    <!-- 简易版图片弹窗 -->
    <el-dialog title="简易版图片" :visible.sync="easyDialogVisible" append-to-body>
      <div class="dialog_box">
        <EasyPictureCanvas
          :data="simpleImageData"
          :myChartList="myChartList"
          :bol="easyDialogVisible"
          ref="downloadEasyImage"
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  selectDiagnose,
  selectById,
  updateDiagnose,
  selectPolicyDeclaration,
  selectDiagnosticLogout,
  getDiagnosticTools,
  updateolicyState,
  downloadPdf,
  saveSpecialistSuggestiond,
  selectByIds,
  downloadPdfReport,
  updateRelease
} from '@/api/diagnosis'
import { pagination } from '@/mixins/pagination'
import { accAdd, base64ToFile } from '@/utils/function.js'
import { apiUpload, apiDownloadFile } from '@/api/common.js'
import EasyPictureCanvas from './EasyImageCanvas.vue'

export default {
  name: 'Diagnosisrecord',
  mixins: [pagination],
  components: { EasyPictureCanvas },
  data() {
    return {
      easyDialogVisible: false, // 简易版图片弹窗
      messageCount: 0, // 提示消息弹窗次数
      // 简易版图片url
      simpleImageData: {
        questionnaireName: '',
        score: 0, // 分数
        name: '', // 提交人
        createTime: '', // 提交时间
        companyName: '', // 企业名称
        diagnosticBasis: '', // 诊断依据
        evaluationSuggestion: '', // 综合评估建议
        isPolicy: [], // 可申报政策列表
        noPolicy: [], // 不可申报政策列表
        imgList: [] // 分析图例
      },
      activeNames: [],
      btns: {
        downloadPdfBtn: false // 禁用下载分析报告按钮
      }, // 控制按钮禁用
      // issShowDonwloadBtn: false, // 展示下载图片按钮
      // 下载分析报告pdf的传参
      downloadParam: {
        id: this.$route.query.diagnoseId, // 诊断记录id
        urls: [] // 分析图片集合
      },
      files: [],
      hasScore: false, //是否有分数
      activeName: 0,
      loading: false,
      recordlist: [], //问题组、问题、分数
      reportScore: 0, //分析图例 得分
      topinfo: {},
      toplist: [], //企业
      citylist: [], //地区
      diagnoseId: this.$route.query.diagnoseId, //诊断书id
      id: this.$route.query.id, //用户id 企业id
      //查询政策的参数
      paramPolicy: {
        isComplex: true, //简易：false;复杂：true
        questionnaireId: this.$route.query.questionnaireId,
        score: 0,
        webId: this.$route.query.id
      },
      policyobj: {}, //申报政策数据
      open: false, //历史诊断记录 弹窗
      historyList: [], //历史诊断记录列表
      historytotal: 0,
      hisparam: {
        diagnoseId: this.$route.query.diagnoseId,
        component: '',
        order: 'desc',
        pageNum: 1,
        pageSize: 10,
        questionnaireId: this.$route.query.questionnaireId
      },
      //获取诊断详情数据下载pdf
      diagnosisresult: {},
      //保存建议
      paramsug: {
        diagnosticBasis: '', //诊断依据
        evaluationSuggestion: '', //综合评估建议
        diagnoseId: this.$route.query.diagnoseId,
        suggest: [
          {
            suggest: '',
            suggestTitle: ''
          }
        ]
      },
      rules: {
        noDeclarationReason: [{ required: true, message: '请输入不通过理由', trigger: 'blur' }]
      }, //提交理由的验证表单
      //手动修改申报数据
      paramdiagnose: {
        declarationPolicyName: null,
        id: null,
        isDeclaration: null,
        noDeclarationReason: null
      },
      visible: false,
      dialogVisible: false,
      myChartList: [], //雷达图数组
      spiclist: [],
      paramList: [],
      h5info: {}
    }
  },
  computed: {
    // 激活的item
    getActiveItem() {
      return function (index) {
        let bol = false
        if (this.activeNames.length > 0) {
          this.activeNames.forEach((el) => {
            if (el === index) {
              bol = true
            }
          })
        } else {
          bol = false
        }

        return bol
      }
    },
    // 计算二级问题组name值
    getSecondItemName() {
      return function (index, i2) {
        const name = (index + 1) * 100 + i2
        return name
      }
    },
    // 计算三级级问题组name值
    getThirdItemName() {
      return function (index, ii2) {
        const name = (index + 1) * 1000 + (index + 1) * 100 + ii2
        return name
      }
    },
    // 根据分数计算等级
    getGrade() {
      if (this.reportScore <= 20) {
        return 'L1'
      } else if (this.reportScore > 20 && this.reportScore <= 40) {
        return 'L2'
      } else if (this.reportScore > 40 && this.reportScore <= 60) {
        return 'L3'
      } else if (this.reportScore > 60 && this.reportScore <= 80) {
        return 'L4'
      } else {
        return 'L5'
      }
    }
    //获取评估建议
    // getTxt() {
    //   if (this.reportScore <= 20) {
    //     return '企业已具备一定的数智化转型基础，能够对核心业务进行流程化管理，并对数据价值有了一定的认知；各部门人员和管理层可以选用一些常见工具零散的进行数据分析；企业信息化建设处于起步阶段；实现对一部分核心业务进行流程化管理，企业数字转型的价值还待进一步挖掘。'
    //   } else if (this.reportScore > 20 && this.reportScore <= 40) {
    //     return '企业针对数字化转型已经或者计划出台相关规划；能够通过自动化、信息化等手段实现单一业务活动的数据共享，能为企业规范化管理提供有力支撑；数据应用已从个人上升至部门或者企业级别；但还不能真正覆盖企业全方位的日常管理和业务发展需求，企业数字化应用的深度还待进一步加强。'
    //   } else if (this.reportScore > 40 && this.reportScore <= 60) {
    //     return '企业数字化转型已步入发展阶段；在智能产线/车间/工厂建设等方面开展了较为广泛的实践；已搭建系统化的数据运营体系,核心业务已实现跨业务、跨部门的集成系统建设和企业级数据共享。但数据的使用并未进入核心业务，数据使用深度不足，无法实现全员数字化运营。'
    //   } else if (this.reportScore > 60 && this.reportScore <= 80) {
    //     return '企业数字化处于先进阶段，清晰地认识到数字化的益处及带来的竞争力,构建了以业务为中心的数据化运营体系；基本上实现了从数据、信息到决策的智能化覆盖，形成了智能化运维体系。各部门使用数据均以赋能业务为出发点;企业已形成数据的良性循环；达到数据赋能业务的目的。'
    //   } else {
    //     return '企业数字化处于领先阶段，数字化程度高，具备成熟的系统及业务战略，实现基于模型持续驱动业务活动的优化和创新,并能够基于数据开创出新的商业模式;内部数据半自动化、全自动化决策能力和人为判断决策较好地融为一体，真正做到数据驱动发展，并持续推进企业数字化转型进程螺旋式上升。实现产业链协同并衍生新的制造模式和商业模式；通过数字化技术和商业模式创新成为了市场的领导者。'
    //   }
    // }
  },
  mounted() {
    this.selectDiagnose()
  },
  created() {
    this.selectById()
    this.ExpertInformationByIdd()
  },
  methods: {
    async saveSpecialistSuggestiond() {
      const list = []

      this.paramList.forEach((item) => {
        var nameArray = item.specialistSuggestion.map((el) => {
          return el.suggestion
        })
        list.push({
          id: item.id,
          diagnoseId: this.paramsug.diagnoseId,
          specialistId: item.specialistId,
          userId: item.userId,
          specialistSuggestion: nameArray
        })
      })

      const { data: res } = await saveSpecialistSuggestiond(list)
      if (res.resultCode == 200) {
        this.updateDiagnose()
      }
    },
    addsugges(index) {
      if (this.paramList[index].specialistSuggestion.length > 4) {
        return
      }
      this.paramList[index].specialistSuggestion.push({
        suggestion: ''
      })
    },
    delsugges(item, i) {
      item = item.specialistSuggestion.splice(i, 1)
    },
    async ExpertInformationByIdd() {
      const { data: res } = await selectByIds({ id: this.paramPolicy.webId })
      if (res.resultCode == 200) {
        this.h5info = res.data
        if( res.data){
           
        this.spiclist = res.data.specialists
        // this.h5info.specialists
        if (this.spiclist.length) {
          this.spiclist.forEach((el) => {
            el.specialistIntro = el.specialistIntro.replace(/<[^>]+>/g, "");
            let list = []
            if (el.specialistSuggestion) {
              el.specialistSuggestion.forEach((o, i) => {
                list.push({
                  suggestion: o
                })
              })
            } else {
              list = [
                {
                  suggestion: ''
                }
              ]
            }
            this.paramList.push({
              name: el.name,
              headPortrait: el.headPortrait,
              diagnoseId: this.diagnoseId,
              id: el.ssId,
              specialistId: el.id,
              userId: this.h5id,
              specialistSuggestion: list
            })
          })
        }
          }
       
      }
    },
    //查询历史诊断一览数据
    async search() {
      this.hisparam.component = this.topinfo.companyName
      const { data: res } = await selectDiagnosticLogout(this.hisparam)
      if (res.resultCode === 200) {
        this.historyList = res.data.list
        this.historytotal = res.data.total
      }
    },
    // 历史诊断记录 详情按钮
    toHistoryRecord(row) {
      this.$router.push({
        path: '/diagnosis/recordinfo',
        query: {
          id: row.webId,
          diagnoseId: row.diagnoseId,
          questionnaireId: row.questionnaireId
          // isSelect: row.isSelect  // 是否查询分析报告-已弃用
        }
      })
      location.reload()
    },
    // 折叠面板修改
    collapseChanged(val) {
      // console.log(val)
    },
    // 点击collapse-item 一级问题组
    collapseFirstItemClick(item, index) {
      // 注：点击事件发生在collapse-item name值变化之后
      this.activeNames = this.activeNames.filter((el) => {
        return el === index
      })
      if (item.results && item.results.length > 0) {
        // 有二级问题组
        this.activeNames.push((index + 1) * 100)
        const results = item.results[0].results
        if (results && results.length > 0) {
          // 有三级问题组
          const name = (index + 1) * 1000 + (index + 1) * 100
          this.activeNames.push(name)
        }
      }
    },
    // 点击collapse-item 二级问题组
    collapseSecondItemClick(item, i2, index) {
      const clickName = (index + 1) * 100 + i2
      this.activeNames = this.activeNames.filter((el) => {
        return el < 100 || el === clickName
      })
      if (item.results && item.results.length > 0) {
        // 有三级问题组
        const name = (index + 1) * 1000 + (index + 1) * 100
        this.activeNames.push(name)
      }
    },
    // 点击collapse-item 三级问题组
    collapseThirdItemClick(ii2, index) {
      const clickName = (index + 1) * 1000 + (index + 1) * 100 + ii2
      this.activeNames = this.activeNames.filter((el) => {
        // el === index ||
        return el < 1000 || el === clickName
      })
    },
    // 获取一级问题组collapse-item样式
    getFirstItemClass(item, index) {
      // index为一级问题组的索引（也是name值）
      const bol1 = this.activeNames.length > 1 // 选中的item个数大于1
      const bol2 = this.activeNames.indexOf(index) !== -1 // 是选中的item
      const bol3 = item.results ? true : false // 最后一级问题组
      let arr = []
      if (!bol3) {
        arr.push('not_icon')
      }
      if (bol1 && bol2) {
        arr.push('active_title')
      }
      return arr
    },
    // 获取二级问题组collapse-item样式
    getSecondItemClass(i1, i2, index) {
      // index为一级问题组的索引（也是name值）
      const clickName = (index + 1) * 100 + i2
      const bol1 = this.activeNames.length > 2 // 选中的item个数大于1
      const bol2 = this.activeNames.indexOf(clickName) !== -1 // 是选中的item
      const bol3 = i1.results ? true : false // 最后一级问题组
      let arr = []
      if (!bol3) {
        arr.push('not_icon')
      }
      if (bol1 && bol2) {
        arr.push('active_title')
      }
      return arr
    },
    // 一级问题组为 企业基本信息 时
    getStrBol(str) {
      if (str === '企业基本信息') {
        return true
      } else {
        return false
      }
    },
    //查询诊断详情  整体
    async selectDiagnose() {
      this.loading = true
      const { data: res } = await selectDiagnose({ id: this.diagnoseId })
      if (res.resultCode === 200) {
        this.recordlist = res.data
        this.activeNames = [0]
        const item = this.recordlist[0]
        if (item.results && item.results.length > 0) {
          // 有二级问题组
          this.activeNames = [0, 100]
          const results = item.results[0].results
          if (results && results.length > 0) {
            // 有三级问题组
            this.activeNames = [0, 100, 1000]
          }
        }
        const bol = this.recordlist[0].atPresentScore != null
        const bol1 = this.recordlist.length > 0
        if (bol) {
          this.hasScore = true
        } else {
          this.hasScore = false
        }
        if (bol1 && bol) {
          //有一级问题组且有分数
          //第一个为大类的分析图例
          this.myChartList = [
            {
              dataMax: [],
              data: {
                name: '整体分析图例',
                value: []
              }
            }
          ]
          this.recordlist.map((item) => {
            //获取总得分
            this.reportScore = accAdd(Number(this.reportScore), Number(item.atPresentScore))
          })
          this.simpleImageData.score = this.reportScore // 分数 简易版图片
          this.paramPolicy.score = this.reportScore
          this.getDiagnosticTools() // 获取总得分之后再获取建议数据
          this.recordlist.map((item, index) => {
            // set雷达图数据
            if (!this.getStrBol(item.groupName)) {
              this.myChartList[0].dataMax.push({
                name: `${item.groupName}` + '(' + item.displayScore + '分' + ')',
                max: 100
              })
              this.myChartList[0].data.value.push(item.displayScore)
              if (item.results) {
                //是否有二级问题组
                if (item.results.length > 0) {
                  //是否有二级问题组  有二级问题组时添加myChartList的子元素
                  this.myChartList.push({
                    dataMax: [],
                    data: {
                      name: item.groupName,
                      value: []
                    }
                  })
                  item.results.map((i) => {
                    if (!i.results) {
                      //是否有三级问题组 最后一级为二级时雷达图数据为二级问题组名称
                      this.myChartList[index + 1].dataMax.push({
                        name: `${i.groupName}` + '(' + i.displayScore + '分' + ')',
                        max: 100
                      })
                      this.myChartList[index + 1].data.value.push(i.displayScore)
                    } else {
                      i.results.map((ii) => {
                        this.myChartList[index + 1].dataMax.push({
                          name: `${ii.groupName}` + '(' + i.displayScore + '分' + ')',
                          max: 100
                        })
                        this.myChartList[index + 1].data.value.push(ii.displayScore)
                      })
                    }
                  })
                }
              }
            }
          })
          this.myChartList.forEach((el) => {
            const obj = {
              img: '',
              name: el.data.name
            }
            this.simpleImageData.imgList.push(obj)
          })
          this.$nextTick(() => {
            this.drawLine()
          })
        }
      } else {
        this.loading = false
        this.$message.error('获取诊断详情信息失败!')
      }
    },
    //查询诊断详情头部数据
    async selectById() {
      const { data: res } = await selectById({ id: this.id })
      if (res.resultCode === 200) {
        this.topinfo = res.data
        this.simpleImageData.companyName = this.topinfo.companyName // 企业名称 简易版图片
        this.simpleImageData.name = this.topinfo.name // 提交人 简易版图片
        if (this.topinfo.industryInvolved != null) {
          if (this.topinfo.industryInvolved.indexOf(',') != -1) {
            this.toplist = this.topinfo.industryInvolved.split(',')
          }
        }
        if (this.topinfo.contactArea != null) {
          if (this.topinfo.contactArea.indexOf(',') != -1) {
            this.citylist = this.topinfo.contactArea.split(',')
          }
        }
      }
    },
    //查询申报政策数据
    async selectPolicyDeclaration() {
      const { data: res } = await selectPolicyDeclaration(this.paramPolicy)
      if (res.resultCode == 200) {
        if (res.data) {
          this.policyobj = res.data
          if (this.policyobj.declarationPolicyVo && this.policyobj.declarationPolicyVo.length > 0) {
            this.policyobj.declarationPolicyVo.forEach((el) => {
              // 遍历区分可申报和不可申报的政策
              if (el.isDeclare) {
                // 可申报政策
                this.simpleImageData.isPolicy.push(el)
              } else {
                // 不可申报政策
                this.simpleImageData.noPolicy.push(el)
              }
            })
          }
        }
      }
    },
    //查询建议部分 右侧数据
    async getDiagnosticTools() {
      let obj = {
        reportScore: this.reportScore, // 总得分
        companyName: '',
        diagnoseState: '',
        id: this.$route.query.diagnoseId,
        pageNum: 1,
        pageSize: 10,
        questionnaireId: 0,
        submitState: ''
      }
      const { data: res } = await getDiagnosticTools(obj)
      if (res.resultCode == 200) {
        this.loading = false
        const item = res.data.list[0]
        this.diagnosisresult = item
        this.paramsug.diagnosticBasis = item.questionnaireDiagnosticBasis // 评估依据
        this.paramsug.evaluationSuggestion = item.questionnaireEvaluationSuggestion // 综合评估建议
        const questionnaireName = item.questionnaireName.split('工具', 1).join() + '分析报告'
        const obj = {
          createTime: item.createTime,
          evaluationSuggestion: item.questionnaireEvaluationSuggestion,
          diagnosticBasis: item.questionnaireDiagnosticBasis,
          questionnaireName
        }
        this.simpleImageData = Object.assign(this.simpleImageData, obj)
        // this.paramsug.evaluationSuggestion = this.getTxt
        if (!this.paramsug.appLinkPicture) {
          this.paramsug.appLinkPicture = ''
        }
        if (this.diagnosisresult.suggests.length > 0) {
          this.paramsug.suggest = item.suggests
        }
        if (this.diagnosisresult.evaluationType == '2') {
          this.selectPolicyDeclaration()
        }
      }
    },
    //发布报告
    // async upReport() {
    //   if (this.diagnosisresult.diagnoseState == 1) {
    //     const { data: res } = await updateRelease({ diagnoseId: this.$route.query.diagnoseId })
    //     if (res.resultCode === 200 && this.messageCount < 1) {
    //       this.$message.success('发布成功!')
    //       this.resetMessageCount()
    //     } else if (this.messageCount < 1) {
    //       this.$message.error('发布失败!')
    //       this.resetMessageCount()
    //     }
    //   } else if (this.messageCount < 1) {
    //     this.$message.warning('请先完成诊断!')
    //     this.resetMessageCount()
    //   }
    // },
    // 下载图片方法 传文件url
    async downloadImg(url) {
      fetch(url).then((res) => {
        res.blob().then((blob) => {
          const a = document.createElement('a'), // 动态创建a标签
            downloadUrl = window.URL.createObjectURL(blob), // 创建blob对象
            name = `${this.diagnosisresult.companyName}-${this.diagnosisresult.questionnaireName}-简易版图片.png` //指定下载文件名
          a.href = downloadUrl
          a.download = name
          a.click() //触发下载
          window.URL.revokeObjectURL(downloadUrl) //释放URL对象
        })
      })
      // const a = document.createElement('a') //创建a标签
      // a.style.display = 'none'
      // a.href = url // 指定下载'链接
      // a.download = `${this.diagnosisresult.companyName}-${this.diagnosisresult.questionnaireName}-简易版图片.png` //指定下载文件名
      // a.click() //触发下载
      // window.URL.revokeObjectURL(a.href) //释放URL对象
      // if (document.body.contains(a)) {
      //   document.body.removeChild(a) //释放标签
      // }
      // // const { data: res } = await apiDownloadFile({ urlStr: url })
      // // if (res.resultCode !== 200) {
      // //   this.$message.error('下载失败!')
      // // }
    },
    // 重置消息提示次数计数器
    resetMessageCount() {
      this.messageCount++
      setTimeout(() => {
        this.messageCount = 0
      }, 1000)
    },
    // 下载简易版图片
    downloadSimpleImage() {
      this.easyDialogVisible = true
      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     this.$refs.downloadEasyImage.downloadImg()
      //   }, 2000)
      // })
    },
    //tab切换
    tabClick(val) {
      this.activeName = val
      if (val == '1' && this.diagnosisresult.evaluationType == '2') {
        this.paramPolicy.isComplex = true
        this.selectPolicyDeclaration()
      } else if (val == '2' && this.diagnosisresult.evaluationType == '2') {
        this.paramPolicy.isComplex = false
        this.selectPolicyDeclaration()
      }
    },
    //修改申报政策的id
    async switchchange(item) {
      if (item.isDeclaration == 1) {
        this.dialogVisible = true
        this.paramdiagnose.diagnoseId = this.$route.query.diagnoseId * 1
        this.paramdiagnose.declarationPolicyName = item.declarationPolicyName
        this.paramdiagnose.id = item.id
        this.paramdiagnose.isDeclaration = item.isDeclaration
      } else {
        this.paramdiagnose = item
        this.paramdiagnose.diagnoseId = this.$route.query.diagnoseId * 1
        const res = await updateolicyState(this.paramdiagnose)
        if (res.data.resultCode == 200) {
          this.$message.success('修改成功！')
          this.selectPolicyDeclaration()
        }
      }
    },
    //取消弹窗
    handleClose() {
      this.paramdiagnose.noDeclarationReason = ''
      this.dialogVisible = false
    },
    //弹窗保存
    async submit() {
      const res = await updateolicyState(this.paramdiagnose)
      if (res.data.resultCode == 200) {
        this.$message.success('修改成功!')
        this.selectPolicyDeclaration()
        this.paramdiagnose.noDeclarationReason = ''
        this.dialogVisible = false
      }
    },
    //打开历史诊断记录
    historyClicck() {
      this.open = true
      this.search()
    },
    //新增建议
    addsugest() {
      this.paramsug.suggest.push({
        suggest: '',
        suggestTitle: ''
      })
    },
    //下载pdf诊断报告
    async downloadPdf() {
      this.btns.downloadPdfBtn = true
      this.loading = true
      // let nowdata = new Date()
      // let year = nowdata.getFullYear()
      // let month = nowdata.getMonth() + 1
      // let date = nowdata.getDate()
      // let nowtime = year + '-'
      // if (month < 10) {
      //   nowtime += '0'
      // }
      // nowtime += month + '-'
      // if (date < 10) {
      //   nowtime += '0'
      // }
      // nowtime += date
      // let download = `${this.diagnosisresult.companyName}-${this.diagnosisresult.questionnaireName}分析报告-${nowtime}.pdf`
      if (this.diagnosisresult.evaluationType === '2') {
        this.$message.warning('政策申报工具分析报告暂时无法下载，敬请期待~')
        this.btns.downloadPdfBtn = false
        this.loading = false
        // const res = await downloadPdf(this.diagnosisresult)
        // if (res.data) {
        //   this.btns.downloadPdfBtn = false
        //   this.loading = false
        //   let blob = new Blob([res.data])
        //   let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        //   const a = document.createElement('a') //创建a标签
        //   a.style.display = 'none'
        //   a.href = href // 指定下载'链接
        //   a.download = download //指定下载文件名
        //   // a.download = "test.png";
        //   a.click() //触发下载
        //   window.URL.revokeObjectURL(a.href) //释放URL对象
        //   if (document.body.contains(a)) {
        //     document.body.removeChild(a) //释放标签
        //   }
        // } else {
        //   this.btns.downloadPdfBtn = false
        //   this.loading = false
        //   this.$message.error('下载失败!')
        // }
      } else if (this.diagnosisresult.evaluationType === '3') {
        this.$message.warning('调查问卷工具分析报告暂时无法下载，敬请期待~')
        this.btns.downloadPdfBtn = false
        this.loading = false
      } else {
        const { data: res } = await downloadPdfReport(this.downloadParam)
        // 返回的是下载路径
        if (res.resultCode === 200) {
          this.btns.downloadPdfBtn = false
          this.loading = false
          window.open(res.message)
          // let href = res.message //创建新的URL
          // const a = document.createElement('a') //创建a标签
          // a.style.display = 'none'
          // a.href = href // 指定下载'链接
          // a.download = download //指定下载文件名
          // a.click() //触发下载
          // window.URL.revokeObjectURL(a.href) //释放URL对象
          // if (document.body.contains(a)) {
          //   document.body.removeChild(a) //释放标签
          // }
        } else {
          this.btns.downloadPdfBtn = false
          this.loading = false
          this.$message.error('下载失败!')
        }
      }
    },
    //删除新增建议
    delsugest(index) {
      this.paramsug.suggest.splice(index, 1)
    },
    //删除分享链接图片
    removeImgAppLinkPicture(val) {
      this.paramsug.appLinkPicture = ''
    },
    //添加分享链接图片
    uploadOneImgShowAppLinkPicture(val) {
      this.paramsug.appLinkPicture = val
    },
    // 分页
    handleSizeChange(val) {
      this.hisparam.pageSize = val
      this.search()
    },
    // 分页
    handleCurrentChange(val) {
      this.hisparam.pageNum = val
      this.search()
    },
    //保存
    async updateDiagnose() {
      const res = await updateDiagnose(this.paramsug)
      if (res.data.resultCode == 200) {
        this.$message.success('保存成功!')
        this.getDiagnosticTools()
      } else {
        this.$message.error(res.data.message)
      }
    },
    //返回上一级
    back() {
      this.$router.go(-1)
    },
    drawLine() {
      this.btns.downloadPdfBtn = true
      let specificEchart = document.getElementsByClassName('specificechart')
      this.myChartList.forEach((item, index) => {
        let myChart = this.$echarts.init(specificEchart[index])
        let option = {
          grid: {
            // 设置图表距离顶部,左侧，右侧和底部的高度
            top: '0px',
            left: '20px',
            right: '20px',
            bottom: '0px'
          },
          color: ['#68A2FF', '#FF9C00', '#0062FF'],
          //配置维度的最大值
          radar: {
            axisName: {
              // color: '#0062FF',
              //backgroundColor: '#999',
              //borderRadius: 3,
              //padding: [3, 5]
              //指示器文字换行 start
              formatter: function (text) {
                var strlength = text.length
                if (strlength % 15 != 0) {
                  text = text.replace(/\S{15}/g, function (match) {
                    //console.log(match)
                    return match + '\n'
                  })
                } else {
                  text = text.replace(/\S{15}/g, function (match) {
                    //console.log(match)
                    return match + '\n'
                  })
                  strlength = text.length
                  text = text.substring(0, strlength - 1)
                }
                return text
              }
            },
            center: ['50%', '60%'], //调位置  第一个左右   第二个上下
            radius: 70, //调大小
            // 雷达图的指示器，用来指定雷达图中的多个变量（维度）
            indicator: item.dataMax,
            //对雷达图形设置成一个圆形,可选 circle:圆形,polygon:多角形(默认)
            shape: 'circle',
            splitArea: {
              itemStyle: {
                //color: '#FF7A00',
                //borderColor: '#FF7A00'
              },
              areaStyle: {
                // 图表背景网格区域的颜色
                //color: 'rgba(71,237,252,.3)'
                //color: '#FF7A00', //分割区域的背景色
              }
            },
            axisLine: {
              show: true, //雷达图中心发出的射线：坐标轴轴线相关设置
              lineStyle: {
                type: 'dashed',
                color: 'rgba(104,162,255,0.9)'
              }
            },
            splitLine: {
              //圆圈线条样式
              lineStyle: {
                color: 'rgba(104,162,255,.9)'
              }
            }
          },
          series: [
            {
              type: 'radar',
              label: {
                show: false, //控制是否显示数值
                fontSize: 12,
                color: '#FF7A00',
                margin: 20
              },
              // areaStyle: {}, //每个雷达图形成一个阴影的面积
              itemStyle: {
                //折线拐点标志的样式
                color: '#FF7A00',
                borderColor: '#FF7A00'
              },
              areaStyle: {
                // 折线围成区域的颜色
                color: '#FF7A00' //分割区域的背景色
              },
              lineStyle: {
                //线条样式
                color: '#FF7A00'
              },
              symbolSize: 4, //圆点大小
              symbol: 'circle', //圆点样式
              data: [item.data]
            }
          ]
        }
        myChart.setOption(option) // 绘制图表
        setTimeout(() => {
          this.loading = false
          var opts = {
            type: 'png', // 导出的格式，可选 png, jpeg
            pixelRatio: 1, // 导出的图片分辨率比例，默认为 1。
            backgroundColor: '#fff' // 导出的图片背景色，默认使用 option 里的 backgroundColor
          }
          //拿到base64 地址 传入files数组
          this.files.push(base64ToFile(myChart.getDataURL(opts), index))
          this.downloadParam.urls.push('')
          this.uploadFile(this.files[index], index)
        }, 1000)
        setTimeout(() => {
          this.btns.downloadPdfBtn = false
        }, 3000)
      })
    },
    // 上传图片
    async uploadFile(item, index) {
      const form = new FormData()
      form.append('file', item)
      const { data: res } = await apiUpload(form)
      if (res.resultCode === 200) {
        this.downloadParam.urls[index] = res.data
        this.simpleImageData.imgList[index].img = res.data
      }
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-collapse-item__content {
  padding-bottom: 0 !important;
}
::v-deep .el-collapse-item__header {
  display: flex;
  align-items: center;
  height: 52px;
  line-height: 48px;
  background-color: #fff;
  color: #303133;
  cursor: pointer;
  border-bottom: 1px solid #ebeef5;
  font-size: 18px;
  font-weight: 500;
  transition: border-bottom-color 0.3s;
  outline: 0;
}
::v-deep .el-collapse {
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ebeef5;
}
::v-deep .el-timeline {
  margin: 0;
  font-size: 14px;
  list-style: none;
  margin-top: 10px;
}
::v-deep .el-timeline-item__tail {
  position: absolute;
  left: 5px;
  height: 100%;
  border-left: 1px solid #448aff;
}
::v-deep .el-timeline-item__node--normal {
  left: -1px;
  width: 4px;
  height: 4px;
  background: #ffffff;
  /* 主色蓝 */
  border: 5px solid #448aff;
}

.dialog_box {
  display: flex;
  justify-content: center;
  background: transparent;
}
.record_info {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  .question {
    padding: 0px 0px;
    .progress_flex {
      margin-top: 10px;
    }
    .load_flex {
      display: flex;
      height: 90vh;
      justify-content: center;
      margin-top: 60px;
    }
    .spise_box {
      border-radius: 10px;
      padding: 10px;
      margin-top: 10px;
      border: 1px solid #ececec;
      .spise_top {
        display: flex;
        justify-content: space-between;
      }
      .blue_color {
        color: #448aff;
        font-size: 16px;
        cursor: pointer;
      }
      .suggestion {
        margin-top: 5px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        .sugges_left {
          width: 90%;
          background: #fafafa;
          height: 120px;
          margin-right: 10px;
          padding: 10px;
          display: flex;
          align-items: flex-start;
        }
        .input_sugges {
          width: 100%;
          height: 120px;
          border: 1px solid #fff;
          background: #fafafa;
          font-size: 16px;
        }
        .blue_index {
          display: inline-block;
          width: 15px;
          height: 15px;
          font-size: 13px;
          text-align: center;
          line-height: 15px;
          border-radius: 4px;
          color: #fff;
          background: #84b2ff;
          cursor: pointer;
        }
        .delete_sugges {
          width: 24px;
          height: 120px;
          color: #fe4b4b;
          font-size: 14px;
          text-align: center;
          display: flex;
          align-items: center;
          background: #ffe7e7;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
    .spise_flex {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 550;
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 5px;
      }
    }

    .serch_input {
      width: 100%;
      height: 48px;
      background: #fafafa;
      padding: 0px 5px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      input {
        width: 140px;
        border: 1px solid #fff;
        background-color: #fff;
        font-size: 14px;
      }
    }
    .question_type {
      margin-top: 10px;
      width: 100%;
      padding: 5px;
      background: #f2f7ff;
      color: #448aff;
      font-size: 16px;
    }
    .input_css {
      background: #fafafa;
      border: 1px solid #fff;
      width: 100%;
      height: 48px;
      padding: 5px 10px;
      border-radius: 4px;
    }
    .question_types {
      margin-top: 10px;
      width: 100%;
      padding: 8px;
      background: #f2f7ff;
      color: #448aff;
      font-size: 16px;
    }
    .name_label {
      margin-top: 10px;
      color: #212121;
      font-size: 18px;
      font-weight: 550;
    }
    .question_top {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      position: relative;
      .blue {
        color: #448aff;
        font-size: 26px;
        font-weight: 550;
      }
      .small {
        font-size: 14px;
        color: #999999;
      }
      .title {
        font-weight: 550;
        font-size: 20px;
        color: #212121;
        padding: 0px 10px;
      }
      .small_num {
        font-size: 14px;
        color: #999999;
      }
      .compny_title {
        position: absolute;
        right: 0px;
        top: 15px;
        color: #212121;
        font-size: 14px;
        font-weight: 550;
      }
    }
  }
  .top_card {
    background-color: #fff;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 8px;
  }
  .card_content {
    display: flex;
    width: 100%;
    margin-top: 20px;
    .card_left {
      min-width: 200px;
      align-items: center;
      border: 0.5px solid #e4e4e4;
      border-radius: 4px;
      height: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      background-color: #fff;
      overflow-y: auto;
      z-index: 9;
      /* 滚动条整体部分 */
      &::-webkit-scrollbar {
        width: 3px;
        height: 9px;
      }
      /* 滚动条里面的滑块 */
      &::-webkit-scrollbar-thumb {
        background-color: rgba(144, 147, 153, 0.3);
        border-radius: 20px;
        transition: background-color 0.3s;
      }
      .navigation_top {
        width: 100%;
        text-align: center;
        font-size: 16px;
        line-height: 22px;
        color: #1a79f6;
        border-bottom: 0.5px solid #e4e4e4;
        padding: 20px;
        box-sizing: border-box;
      }
      .navigation_content {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 20px 10px;
        box-sizing: border-box;
        .el-collapse {
          width: 100%;
          border: none;
          border-left: #d4d4d4 1px solid;
          padding: 10px;
          position: relative;
          .first_round,
          .second_round {
            position: absolute;
            left: -8px;
            width: 13px;
            height: 13px;
            background-color: #fff;
            border-radius: 50%;
            border: 1px solid #d4d4d4;
            z-index: 999;
          }
          .first_round {
            top: -10px;
          }
          .second_round {
            bottom: -10px;
          }
        }
        ::v-deep .el-collapse-item__header {
          border: none;
        }
        // ::v-deep .el-collapse-item__wrap {
        //   display: none;
        // }
        ::v-deep .is-active {
          color: #4e93fb;
          background-color: #ddeaff;
        }
        .nav_level_one,
        .nav_level_two,
        .nav_level_three {
          position: relative;
          .li_round {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #d4d4d4;
            border: 1px solid #d4d4d4;
            position: absolute;
            top: 22px;
            left: -14px;
            z-index: 999;
          }
          .triangle_box {
            position: absolute;
            top: 18px;
            left: -14px;
            //在三角形底边设置一个边界颜色
            //其它3边设置相同颜色
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
            border-left: 10px solid #4e93fb;
            border-right: 10px solid transparent;
          }
        }

        .nav_level_one {
          font-size: 16px;
        }
        .nav_level_two {
          font-size: 14px;
        }
        .nav_level_three {
          font-size: 12px;
        }
        .not_icon ::v-deep i {
          display: none;
        }
        // .active_title::v-deep .is-active:first-child {
        //   color: #4e93fb;
        //   background-color: #fff !important;
        // }

        .left_border_box_list {
          position: relative;
          height: 100%;
          border-left: 1px solid #d4d4d4;
          box-sizing: border-box;
          // margin-right: 10px;
        }
      }
    }
    .body_card {
      flex: 1;
      margin-left: 16px;
      height: 76vh;
      overflow-y: auto;
      /* 滚动条整体部分 */
      &::-webkit-scrollbar {
        width: 3px;
        height: 9px;
      }
      /* 滚动条里面的滑块 */
      &::-webkit-scrollbar-thumb {
        background-color: rgba(144, 147, 153, 0.3);
        border-radius: 20px;
        transition: background-color 0.3s;
      }
    }
    .collapse_title {
      display: flex;
      span {
        font-size: 20px;
        font-weight: 500;
        color: #333333;
      }
      .atPresentScore {
        font-size: 24px;
        font-weight: 500;
        color: #4e93fb;
        margin-left: 20px;
      }
    }
    .top_start {
      font-size: 14px;
      color: #b6b6b6;
      background-color: #fafafa;
      overflow: auto;
      padding: 10px 20px;
      box-sizing: border-box;
      .txt {
        overflow-wrap: normal;
        font-size: 14px;
        color: #333;
      }
      .not_option_txt {
        overflow-wrap: normal;
        font-size: 14px;
        line-height: 22px;
        color: #8b8b8b;
        padding: 4px 0;
      }
      .not_select_options {
        font-size: 14px;
        line-height: 22px;
        color: #8b8b8b;
        margin-bottom: 10px;
      }
      .order_box {
        display: inline-block;
        height: 16px;
        width: 16px;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #fff;
        background-color: #314858;
        margin-right: 6px;
      }
    }
  }
}

::v-deep .head_img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .img_title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 10px 5px 10px;
    img {
      width: 68px;
      height: 68px;
    }
    .title {
      margin: 0px 0px 0px 10px;
      .merchant_name {
        font-weight: 550;
        font-size: 20px;
        color: #1f2d3d;
        margin: 0px;
        margin-bottom: 10px;
      }
      .merchant_type {
        position: relative;
        top: 5px;
        padding: 4px 8px;
        color: #fe7d4a;
        background: #ffffff;
        border: 1px solid #fe7d4a;
        border-radius: 4px;
        font-size: 13px;
        line-height: 28px;
        text-align: center;
      }

      .merchant_city {
        margin-top: 13px;
        display: inline-block;
        color: #999999;
        padding: 0px 5px;
      }
      .merchant_phone {
        color: #999999;
        padding: 0px 5px;
      }
      .merchant_companyName {
        color: #999999;
        padding: 0px 5px;
      }
    }
  }
  .right_box {
    display: flex;
    flex-flow: column;
    align-items: center;
    .sumit_num {
      font-weight: 550;
      font-size: 20px;
      color: #448aff;
      margin-bottom: 10px;
    }
  }
  .right_txt {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: absolute;
    right: 0px;
    span {
      color: #555;
      font-size: 14px;
      margin-right: 10px;
    }
    .large_font {
      font-size: 26px;
      font-weight: 500;
    }
    .policy_num {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 36px;
      text-align: center;
      color: #212121;
    }
  }
}
.tabs {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 20px;
  padding-top: 20px;
  box-shadow: 0 1px 1px -1px;
  .tab {
    height: 40px;
    color: #9b9ca0;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .activeTab {
    height: 40px;
    color: #fff;
    padding: 10px;
    background-color: #448aff;
    box-sizing: border-box;
    cursor: pointer;
  }
  .txt {
    font-size: 12px;
    color: #9b9ca0;
    margin-left: auto;
  }
}
.right_buttom {
  display: flex;
  .btn_item {
    // cursor: pointer;
    margin: 0 10px;
    position: relative;
    // .show_btns {
    //   width: 100%;
    //   text-align: center;
    //   background-color: #fff;
    //   margin-bottom: 10px;
    //   padding: 10px;
    //   box-sizing: border-box;
    //   position: absolute;
    //   top: 100%;
    //   left: 0;
    //   z-index: 999;
    //   box-shadow: 0px 4px 4px rgba(202, 202, 202, 0.25);
    //   .span_btn {
    //     cursor: pointer;
    //     margin: 10px;
    //   }
    // }
  }
}
.right_reception{
  .reception_expert{
    background: #FAFAFA;
    padding: 10px;
    margin-top: 5px;
    border-radius: 4px;
    .txt_size{
      font-size: 14px;
      margin-top: 5px;
    }
    .expert_top{
      display: flex;
      align-items: center;
      img{
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      span{
        margin-left: 5px;
      }
    }
  }
    .reception_tittle{
      width: 86px;
      height: 26px;
      color: #4E93FB;
      border: 1px solid #4E93FB;
      text-align: center;
      line-height: 26px;
      border-radius: 2px;
      font-size: 14px;
    }
    .reception_name{
      display: flex;
      margin-top: 10px;
      .reception_item{
        background: #FAFAFA;
        height: 75px;
        width: 280px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        padding: 0px 10px;
        img{
          width: 55px;
          height: 55px;
          border-radius: 50%;
          margin-right: 5px;
        }
        .bottom{
          .bottom_name{
            font-weight: 550;
            margin-bottom: 5px;
            .color_txt{
              color: #999999;
              margin-left: 5px;
              font-weight: 500;
            }
          }
          .bottom_txt{
            color: #999999;
            margin-top: 5px;
          }
        }
      }
    }
  }
.right_content {
  margin-top: 30px;
 
  .right_item {
    display: flex;
    flex-flow: column;
    border-bottom: #e8e8e8 1px dotted;
    margin-bottom: 10px;
    .item_tittle {
      width: fit-content;
      color: #448aff;
      text-align: center;
    }
    .flex_xian {
      margin-top: 5px;
    }
    .xianch_img {
      width: 100%;
      display: flex;
      .img {
        width: 125px;
        height: 125px;
        border-radius: 4px;
        margin-right: 5px;
      }
    }
    .btn {
      color: #448aff;
      cursor: pointer;
      padding-right: 20px;
      margin-left: auto;
      margin-right: 20px;
    }
  }
  .legend {
    display: flex;
    justify-content: space-between;
    color: #448aff;
  }
  .declarable_policy {
    .policy_optioin {
      background: #fafafa;
      color: #448aff;
      font-size: 14px;
      margin: 16px;
      span {
        font-size: 14px;
        color: #333333;
      }
    }
    .policy_optioinNo {
      background: #fafafa;
      color: #999999;
      font-size: 14px;
      margin: 16px;
      span {
        font-size: 14px;
        color: #d4d4d4;
      }
    }
  }
}
.part {
  display: flex;
  align-items: center;
}
.part_top {
  display: flex;
  justify-content: space-between;
  i {
    margin-right: 20px;
  }
}

.dialog-footer {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}
.caption {
  font-size: 16px;
  font-weight: 550;
  color: #333333;
}
.caption_chid {
  font-size: 14px;
  color: #afafaf;
}
::v-deep .part_txt .el-textarea__inner {
  display: block;
  height: 120px;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  font-size: inherit;
  color: #606266;
  background-color: #fafafa;
  background-image: none;
  border: 1px solid #fafafa;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
::v-deep .part_txt .el-input__inner {
  -webkit-appearance: none;
  background-color: #fafafa;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #fafafa;
  border-bottom: 1px solid #dcdfe6;

  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
::v-deep .declarable_policy .el-collapse-item__header {
  display: flex;
  align-items: center;
  height: 52px;
  line-height: 48px;
  background-color: #fff;
  color: #303133;
  cursor: pointer;
  border-bottom: 1px solid #ebeef5;
  font-size: 18px;
  font-weight: 500;
  transition: border-bottom-color 0.3s;
  outline: 0;
  padding: 10px 0px;
}
.legend_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  .item_name {
    min-width: 200px;
    height: 200px;
    border-right: #448aff 1px dotted;
    display: flex;
    align-items: center;
    justify-content: center;
    .img_box {
      width: 36px;
      height: 36px;
      margin-right: 5px;
      img {
        width: 36px;
        height: 36px;
      }
    }
    span {
      font-size: 18px;
      font-weight: 500;
      color: #0062ff;
    }
  }
}
.box_border {
  padding: 5px 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  border: #4e93fb 1px solid;
  span {
    font-size: 16px;
  }
}
.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .item1 {
    overflow: auto;
    width: 80%;
  }
  span {
    font-size: 16px;
  }
  .label {
    min-width: 120px;
    margin-right: 20px;
  }
}
.i_box {
  display: flex;
  align-items: center;
  i {
    cursor: pointer;
  }
}
.common_input {
  width: 60%;
}
.group_name_one {
  font-weight: 500 !important;
  font-size: 20px !important;
  color: #333 !important;
}
.group_name_two {
  font-weight: 500 !important;
  font-size: 18px !important;
  color: #4b4b4b !important;
}
.group_name_three {
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #4b4b4b !important;
}
.corlor1 {
  color: #999 !important;
}
.corlor2 {
  color: #4e93fb !important;
}
</style>
